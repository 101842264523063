<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{'show': shallShowUserProfileSidebar || shallShowActiveChatContactSidebar || mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar=shallShowActiveChatContactSidebar=shallShowUserProfileSidebar=false"
    />

    <!-- Main Area -->
    <section class="chat-app-window">

      <!-- Start Chat Logo -->
      <div
        v-if="!activeChat.id"
        class="start-chat-area"
      >
        <div class="mb-1 start-chat-icon">
          <feather-icon
            icon="MessageSquareIcon"
            size="56"
          />
        </div>
        <h4
          class="sidebar-toggle start-chat-text text-center"
          @click="startConversation"
        >
          Start Conversation

        </h4>
      </div>

      <!-- Chat Content -->
      <div
        v-else
        class="active-chat"
      >
        <!-- Chat Navbar -->
        <div class="chat-navbar">
          <header class="chat-header">

            <!-- Avatar & Name -->
            <div class="d-flex align-items-center">

              <!-- Toggle Icon -->
              <div class="sidebar-toggle d-block d-lg-none mr-1">
                <feather-icon
                  icon="MenuIcon"
                  class="cursor-pointer"
                  size="21"
                  @click="mqShallShowLeftSidebar = true"
                />
              </div>

              <b-avatar
                size="36"
                :text="activeChat.firstName[0]+activeChat.lastName[0]"
                class="mr-1 cursor-pointer badge-minimal"
                badge
                :badge-variant="resolveAvatarBadgeVariant(activeChat.status)"
                @click.native="shallShowActiveChatContactSidebar=true"
              />
              <h6 class="mb-0">
                {{ activeChat.firstName }} {{ activeChat.lastName }}
              </h6>
            </div>

            <!-- Contact Actions -->
            <div class="d-flex align-items-center">
              <feather-icon
                icon="PhoneCallIcon"
                size="17"
                class="cursor-pointer d-sm-block d-none mr-1"
              />
              <feather-icon
                icon="VideoIcon"
                size="17"
                class="cursor-pointer d-sm-block d-none mr-1"
              />
              <feather-icon
                icon="SearchIcon"
                size="17"
                class="cursor-pointer d-sm-block d-none mr-50"
              />
              <div class="dropdown">
                <b-dropdown
                  variant="link"
                  no-caret
                  toggle-class="p-0"
                  right
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="17"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item>
                    View Contact
                  </b-dropdown-item>
                  <b-dropdown-item>
                    Mute Notifications
                  </b-dropdown-item>
                  <b-dropdown-item>
                    Block Contact
                  </b-dropdown-item>
                  <b-dropdown-item>
                    Clear Chat
                  </b-dropdown-item>
                  <b-dropdown-item>
                    Report
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </header>
        </div>

        <!-- User Chat Area -->
        <vue-perfect-scrollbar
          ref="refChatLogPS"
          :settings="perfectScrollbarSettings"
          class="user-chats scroll-area"
        >
          <chat-log
            :chat-data="activeChat"
            :profile-user-avatar="profileUserDataMinimal.firstName[0]+profileUserDataMinimal.lastName[0]"
          />
        </vue-perfect-scrollbar>

        <!-- Message Input -->
        <b-form
          class="chat-app-form"
          @submit.prevent="sendMessage"
        >
          <b-input-group class="input-group-merge form-send-message mr-1">
            <b-form-input
              v-model="chatInputMessage"
              placeholder="Enter your message"
            />
          </b-input-group>
          <b-button
            variant="primary"
            type="submit"
          >
            Send
          </b-button>
        </b-form>
      </div>
    </section>

    <!-- Active Chat Contact Details Sidebar -->
    <chat-active-chat-content-details-sidedbar
      :shall-show-active-chat-contact-sidebar.sync="shallShowActiveChatContactSidebar"
      :contact="activeChat || {}"
    />

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <!-- Removed from "chat-left-sidebar" for now: :active-chat-contact-id="activeChat ? activeChat.id : null" -->
      <chat-left-sidebar
        :chats-contacts="chatsContacts"
        :contacts="contacts"

        :active-chat-contact-id="activeChat ? activeChat.id : null"
        :shall-show-user-profile-sidebar.sync="shallShowUserProfileSidebar"
        :profile-user-data="profileUserData"
        :profile-user-minimal-data="profileUserDataMinimal"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        @show-user-profile="showUserProfileSidebar"
        @open-chat="openChatOfContact"
      />
    </portal>
  </div>
</template>

<script>
import {
  ref, onUnmounted, nextTick,
} from '@vue/composition-api'
import {
  BAvatar, BDropdown, BDropdownItem, BForm, BInputGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
// import { formatDate } from '@core/utils/filter'
import { $themeBreakpoints } from '@themeConfig'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import { API, graphqlOperation } from 'aws-amplify'
import store from '@/store'
import * as queries from '@/libs/graphql/queries'
// eslint-disable-next-line import/no-extraneous-dependencies
// import { ulid } from 'ulid'

import ChatLeftSidebar from './ChatLeftSidebar.vue'
import chatStoreModule from './chatStoreModule'
import ChatActiveChatContentDetailsSidedbar from './ChatActiveChatContentDetailsSidedbar.vue'
import ChatLog from './ChatLog.vue'
import useChat from './useChat'

export default {
  components: {

    // BSV
    BAvatar,
    BDropdown,
    BDropdownItem,
    BForm,
    BInputGroup,
    BFormInput,
    BButton,

    // 3rd Party
    VuePerfectScrollbar,

    // SFC
    ChatLeftSidebar,
    ChatActiveChatContentDetailsSidedbar,
    ChatLog,
  },

  // Currently users will only subscribe to a chat when the chat is clikced on. Tried to store subscriptions in vuex, but it wasn't wokring correctly.

  setup(props, ctx) {
    const CHAT_APP_STORE_MODULE_NAME = 'app-chat'

    // Register module
    if (!store.hasModule(CHAT_APP_STORE_MODULE_NAME)) store.registerModule(CHAT_APP_STORE_MODULE_NAME, chatStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CHAT_APP_STORE_MODULE_NAME)) store.unregisterModule(CHAT_APP_STORE_MODULE_NAME)
    })

    const { resolveAvatarBadgeVariant } = useChat()

    // Scroll to Bottom ChatLog
    const refChatLogPS = ref(null)
    const scrollToBottomInChatLog = () => {
      const scrollEl = refChatLogPS.value.$el || refChatLogPS.value
      scrollEl.scrollTop = scrollEl.scrollHeight
    }

    //
    //
    //
    //
    //
    //
    //
    // ------------------------------------------------
    // Chats & Contacts
    // ------------------------------------------------

    const profileUserDataMinimal = ref({}) // Will contain id, name, avatar & status
    const chatsContacts = ref([])
    const contacts = ref([])
    const currentUser = ref(ctx.parent.$store.getters['authentication/user'])
    profileUserDataMinimal.value = { ...currentUser.value, status: 'online' }
    const activeChat = ref({})
    const chatInputMessage = ref('')
    const subscriptions = ref({})

    // ToDo: The subscription for new userConversations only returns one associated user, only the user that's logged in is in the associated list. So for now, after data is recieved from the subscription, the user conversations refrech function is called

    // Subscribes to new converstation
    if (!('userConversations' in subscriptions.value)) {
      const subscriptionToUserConversations = API.graphql(
        graphqlOperation(queries.subscribeToNewUserConversations, { userId: currentUser.value.id }),
      ).subscribe({
        next: () => {
          store.dispatch('chat/refreshUserConversations')
            .then(response => { chatsContacts.value = response.data.getCurrentUser.conversations.userConversations })
        },
        error: error => error,
      })
      subscriptions.value.userConversations = subscriptionToUserConversations
    }

    // Fetch user convesations.
    store.dispatch('chat/refreshUserConversations')
      .then(response => { chatsContacts.value = response.data.getCurrentUser.conversations.userConversations })

    // ToDo: Currently all users are fetched, fix to paginate.
    // Fetch all users.
    store.dispatch('chat/getAllUsers')
      .then(response => { contacts.value = response.data.allUser.filter(i => i.id !== currentUser.value.id) }) // This removes the current user from the list that returns when querying all users.

    //
    //
    //
    //
    //
    //
    // ------------------------------------------------
    // Single Chat
    // ------------------------------------------------

    const openChatOfContact = async contact => {
      // Reset send message input value
      chatInputMessage.value = ''
      // Get messages for chat
      if (contact.conversationId) {
        await store.dispatch('chat/getMessages', contact.conversationId)
          .then(response => {
            activeChat.value = { ...contact, chat: response.reverse() }

            nextTick(() => { scrollToBottomInChatLog() })
          })
        // If not already subscribing, subscribe to new messages in the chat.
        if (!(contact.conversationId in subscriptions.value)) {
          const subscription = API.graphql(
            graphqlOperation(queries.subscribeToNewMessages, { conversationId: contact.conversationId }),
          ).subscribe({
            next: ({ value }) => {
              if (activeChat.value.id === value.data.subscribeToNewMessage.sender) {
                activeChat.value.chat.push(value.data.subscribeToNewMessage)
              }

              // Need to get conversations so the last message is updated for the user receving the new message, otherwise the last message will only be updated for the user sending the message.
              store.dispatch('chat/refreshUserConversations')
                .then(response => {
                  chatsContacts.value = response.data.getCurrentUser.conversations.userConversations
                })
              nextTick(() => { scrollToBottomInChatLog() })
            },
            error: error => error,
          })
          subscriptions.value[contact.conversationId] = subscription
          // store.dispatch('chat/saveUserSubscriptions', { conversationId: contact.conversationId, subscription })
        }
      } else {
        // There is no conversation so just retrun the user's details.
        activeChat.value = { ...contact }
      }
      // if SM device =>  Close Chat & Contacts left sidebar
      // eslint-disable-next-line no-use-before-define
      mqShallShowLeftSidebar.value = false
    }

    //
    //
    //
    //
    //
    //
    //
    // ------------------------------------------------
    // Send Message
    // ------------------------------------------------

    const sendMessage = async () => {
      if (!chatInputMessage.value) return

      function escapingBackslash(string) {
        return string.replace(/[\]\\]/g, '\\$&')
      }

      const currentUserId = currentUser.value.id
      const content = escapingBackslash(chatInputMessage.value)

      const conversationIdFromActiveChat = activeChat.value.conversationId

      chatInputMessage.value = ''

      let conversationIdFromNewlyCreatedConversation
      if (activeChat.value.conversationId || activeChat.value.chat) {
        await store.dispatch('chat/sendMessageToUser', { content, conversationId: conversationIdFromActiveChat, sender: currentUserId })
          .then(() => {
            if (!activeChat.value.conversationId) {
              activeChat.value.conversationId = conversationIdFromActiveChat
            }
          // activeChat.value.chat.push(content)
          })
      } else {
        const newConvo = {
          name: `Conversation between ${currentUser.value.id} & ${activeChat.value.id}`,
        }

        await API.graphql({ query: queries.createConversation, variables: newConvo })
          .then((async result => {
            conversationIdFromNewlyCreatedConversation = await result.data.createConversation.id
            // ToDo: Make One Api Call
            await store.dispatch('chat/createUserConversation', { conversationId: conversationIdFromNewlyCreatedConversation, userId: activeChat.value.id })
            await store.dispatch('chat/createUserConversation', { conversationId: conversationIdFromNewlyCreatedConversation, userId: currentUser.value.id })
          }))

        await store.dispatch('chat/sendMessageToUser', { content, conversationId: conversationIdFromNewlyCreatedConversation, sender: currentUserId })
          .then(() => {
            if (!activeChat.value.conversationId) {
              activeChat.value.conversationId = conversationIdFromNewlyCreatedConversation
            }
          // activeChat.value.chat.push(content)
          })
      }

      // await store.dispatch('chat/refreshUserConversations')
      //   .then(response => { chatsContacts.value = response.data.getCurrentUser.conversations.userConversations })

      // Needed to put newly started conversations in the chat section. Otherwise, if you start a chat it won't appear in your chat's section.
      openChatOfContact(activeChat.value)
      nextTick(() => { scrollToBottomInChatLog() })
    }

    //
    //
    //
    //
    //
    //
    //
    // ------------------------------------------------
    // Side Bar
    // ------------------------------------------------

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    // User Profile Sidebar
    // ? Will contain all details of profile user (e.g. settings, about etc.)
    const profileUserData = ref({})

    const shallShowUserProfileSidebar = ref(false)

    const showUserProfileSidebar = () => {
      profileUserData.value = currentUser.value
      shallShowUserProfileSidebar.value = true
    }

    // Active Chat Contact Details
    const shallShowActiveChatContactSidebar = ref(false)

    // UI + SM Devices
    // Left Sidebar Responsiveness
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()
    const startConversation = () => {
      if (store.state.app.windowWidth < $themeBreakpoints.lg) {
        mqShallShowLeftSidebar.value = true
      }
    }

    return {
      // Filters
      // formatDate,

      // useChat
      resolveAvatarBadgeVariant,

      // Chat & Contacts
      chatsContacts,
      contacts,

      // Single Chat
      refChatLogPS,
      activeChat,
      chatInputMessage,
      openChatOfContact,
      sendMessage,

      // Profile User Minimal Data
      profileUserDataMinimal,

      // User Profile Sidebar
      profileUserData,
      shallShowUserProfileSidebar,
      showUserProfileSidebar,

      // Active Chat Contact Details
      shallShowActiveChatContactSidebar,

      // UI
      perfectScrollbarSettings,

      // UI + SM Devices
      startConversation,
      mqShallShowLeftSidebar,
    }
  },
  methods: {
    // log(value) {
    //   console.log(value)
    // },
  },
}
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";
</style>
