<template>
  <div class="chats">
    <div
      v-for="(msgGrp, index) in formattedChatData.formattedChatLog"
      :key="msgGrp.sender+String(index)"
      class="chat"
      :class="{'chat-left': msgGrp.sender === formattedChatData.contact.id}"
    >

      <div class="chat-avatar">
        <b-avatar
          size="36"
          class="avatar-border-2 box-shadow-1"
          variant="secondary"
          :text="msgGrp.sender === formattedChatData.contact.id ? formattedChatData.contact.avatar : profileUserAvatar"
        />
      </div>
      <div class="chat-body">
        <div
          v-for="msgData in msgGrp.messages"
          :key="msgData.id"
          v-b-tooltip.hover.right="`${msgData.time}`"
          class="chat-content"
        >
          <p>{{ msgData.msg }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { BAvatar, VBTooltip } from 'bootstrap-vue'

// ToDo: Make time more readable with the moment package - https://stackoverflow.com/questions/28271904/javascript-reformat-date-string

export default {
  components: {
    BAvatar,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    chatData: {
      type: Object,
      required: true,
    },
    profileUserAvatar: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const formattedChatData = computed(() => {
      const contact = {
        id: props.chatData.id,
        avatar: props.chatData.firstName[0] + props.chatData.lastName[0],
      }

      const chatLog = props.chatData.chat || []

      let chatMessageSenderId = chatLog[0] ? chatLog[0].sender : undefined
      let msgGroup = {
        sender: chatMessageSenderId,
        messages: [],
      }

      const formattedChatLog = []
      chatLog.forEach((msg, index) => {
        if (chatMessageSenderId === msg.sender) {
          msgGroup.messages.push({
            msg: msg.content,
            time: msg.createdAt,
          })
        } else {
          chatMessageSenderId = msg.sender
          formattedChatLog.push(msgGroup)
          msgGroup = {
            sender: msg.sender,
            messages: [{
              msg: msg.content,
              time: msg.createdAt,
            }],
          }
        }
        if (index === chatLog.length - 1) formattedChatLog.push(msgGroup)
      })
      // formattedChatLog.forEach(i => i.messages.reverse())
      return {
        formattedChatLog,
        contact,
        profileUserAvatar: props.profileUserAvatar,
      }
    })

    return {
      formattedChatData,
    }
  },
  // methods: {
  //   log(value) {
  //     console.log(value)
  //   },
  // },
}
</script>

<style>

</style>
