<template>
  <component
    :is="tag"
    v-on="$listeners"
  >
    <!-- :src="user.avatar" -->
    <!-- :badge-variant="resolveAvatarBadgeVariant(user.status)" -->
    <!-- :badge="isChatContact" -->

    <b-avatar
      size="42"
      :text="user ? user.firstName[0]+user.lastName[0] : 'user'"

      class="badge-minimal"

      variant="secondary"
    />
    <div class="chat-info flex-grow-1">
      <h5 class="mb-0">
        {{ user.firstName }} {{ user.lastName }}
      </h5>
      <p
        :class="newMessageReceived() & tally > 0 ? 'card-text text-truncate font-weight-bolder': 'card-text text-truncate'"
      >
        <!-- {{ isChatContact ? user.chat.lastMessage.message : user.about }} -->
        {{ lastMessageFrom(user) }} {{ user.lastMessage ? user.lastMessage.content : user.email }}

      </p>
    </div>
    <div
      v-if="isChatContact"
      class="chat-meta text-nowrap"
    >
      <!-- <small class="float-right mb-25 chat-time">{{ formatDateToMonthShort(user.chat.lastMessage.time, { hour: 'numeric', minute: 'numeric' }) }}</small> -->
      <!-- <b-badge
        v-if="user.chat.unseenMsgs"
        pill
        variant="primary"
        class="float-right"
      >
      </b-badge> -->
      <b-badge
        v-if="newMessageReceived() & tally > 0"
        pill
        variant="primary"
        class="float-right"
      >
        {{ tally }}
      </b-badge>
      <!-- {{ log(activeChatContactId) }} -->

    </div>
  </component>
</template>

<script>
import { BAvatar, BBadge } from 'bootstrap-vue'
import { formatDateToMonthShort } from '@core/utils/filter'
import { ref } from '@vue/composition-api'
import useChat from './useChat'

export default {
  components: {
    BAvatar,
    BBadge,
  },
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    activeChatContactId: {
      type: String,
      default: null,
    },
    user: {
      type: Object,
      required: true,
    },
    isChatContact: {
      type: Boolean,
      dedfault: false,
    },
  },
  setup(props) {
    const { resolveAvatarBadgeVariant } = useChat()

    const newMessage = ref({})

    const tally = ref(0)

    const newMessageReceived = () => {
      // Checks if the user is currently clicked on the chat with new messages (returns false)
      if (props.activeChatContactId !== props.user.id) {
        // Checks if the new message belongs to any of the chats.
        if (newMessage.value.sender === props.user.id) {
          return true
        }
      } else {
        tally.value = 0
        return false
      }
      return null
    }
    return {
      formatDateToMonthShort, resolveAvatarBadgeVariant, newMessage, newMessageReceived, tally,
    }
  },
  watch: {
    user(newVal, oldVal) {
      if (newVal.lastMessage.content !== oldVal.lastMessage.content) {
        this.tally += 1
        this.newMessage = newVal.lastMessage
      }
    },
  },
  methods: {
    lastMessageFrom(sender) {
      if (sender.lastMessage) {
        if (sender.lastMessage.sender === this.$store.getters['authentication/user'].id) {
          return 'Me: '
        } return null
      }
      return null
    },
  },
}
</script>

<style>

</style>
