<template>
  <div class="sidebar-left">
    <div class="sidebar">

      <!-- Logged In User Profile Sidebar -->
      <user-profile-sidebar
        :shall-show-user-profile-sidebar="shallShowUserProfileSidebar"
        :profile-user-data="profileUserData"
        @close-sidebar="$emit('update:shall-show-user-profile-sidebar', false)"
      />

      <!-- Sidebar Content -->
      <div
        class="sidebar-content"
        :class="{'show': mqShallShowLeftSidebar}"
      >

        <!-- Sidebar close icon -->
        <span class="sidebar-close-icon">
          <feather-icon
            icon="XIcon"
            size="16"
            @click="$emit('update:mq-shall-show-left-sidebar', false)"
          />
        </span>

        <!-- Header -->
        <div class="chat-fixed-search">
          <div class="d-flex align-items-center w-100">
            <div class="sidebar-profile-toggle">

              <b-avatar
                size="42"
                class="cursor-pointer badge-minimal avatar-border-2"
                :text="profileUserMinimalData.firstName[0]+profileUserMinimalData.lastName[0]"
                variant="secondary"
                badge
                badge-variant="success"
                @click.native="$emit('show-user-profile')"
              />
            </div>
            <!-- Search -->
            <b-input-group class="input-group-merge ml-1 w-100 round">
              <b-input-group-prepend is-text>
                <feather-icon
                  icon="SearchIcon"
                  class="text-muted"
                />
              </b-input-group-prepend>
              <b-form-input
                v-model="searchQuery"
                placeholder="Search first names..."
              />
            </b-input-group>
          </div>
        </div>

        <!-- ScrollArea: Chat & Contacts -->
        <vue-perfect-scrollbar
          :settings="perfectScrollbarSettings"
          class="chat-user-list-wrapper list-group scroll-area"
        >

          <!-- Chats Title -->
          <h4 class="chat-list-title">
            Chats
          </h4>

          <!-- Chats -->
          <ul class="chat-users-list chat-list media-list">
            <!-- Removed from "chat-contact" for now: " -->

            <chat-contact
              v-for="contact in filteredChatsContacts"
              :key="contact.id"
              :user="contact"
              :active-chat-contact-id="activeChatContactId"
              :class="{'active': activeChatContactId === contact.id}"
              tag="li"

              is-chat-contact
              @click="$emit('open-chat', contact)"
            />
          </ul>

          <!-- Contacts Title -->
          <h4 class="chat-list-title">
            Contacts
          </h4>

          <!-- Contacts -->
          <ul class="chat-users-list contact-list media-list">
            <!-- {{ log(filteredContacts) }} -->
            <chat-contact
              v-for="contact in filteredContacts"
              :key="contact.id"
              :user="contact"
              tag="li"
              @click="$emit('open-chat', contact)"
            />
          </ul>
        </vue-perfect-scrollbar>
      </div>

    </div>
  </div>
</template>

<script>
import {
  BAvatar, BInputGroup, BInputGroupPrepend, BFormInput,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { ref, computed } from '@vue/composition-api'

import ChatContact from './ChatContact.vue'
import UserProfileSidebar from './UserProfileSidebar.vue'

export default {
  components: {

    // BSV
    BAvatar,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,

    // 3rd party
    VuePerfectScrollbar,

    // SFC
    ChatContact,
    UserProfileSidebar,
  },
  props: {
    chatsContacts: {
      type: Array,
      required: true,
    },
    contacts: {
      type: Array,
      required: true,
    },
    shallShowUserProfileSidebar: {
      type: Boolean,
      required: true,
    },
    profileUserData: {
      type: Object,
      required: true,
    },
    profileUserMinimalData: {
      type: Object,
      required: true,
    },
    activeChatContactId: {
      type: String,
      default: null,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, ctx) {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const resolveChatContact = userId => props.contacts.find(contact => contact.id === userId)

    // Search Query
    const searchQuery = ref('')
    const currentUser = ref(ctx.parent.$store.getters['authentication/user'])
    const userConversations = computed(() => props.chatsContacts.filter(i => i.associated))

    // If a a user in the contact list has already has a chat with the current userId, the conversation Id is inserted into the contact object. THis is done because if there is no conversaionId in the contact object a conversation is created before the message is sent.

    // eslint-disable-next-line consistent-return
    const searchFilterFunctionForContacts = contact => (contact.firstName ? contact.firstName.toLowerCase().includes(searchQuery.value.toLowerCase()) : false)

    // Currently only searches users by first name.

    const filteredContacts = computed(() => {
      const contactsAndContactsWithChats = props.contacts

      props.contacts.filter(i => {
        userConversations.value.forEach(element => {
          element.associated.forEach(el => {
            if (el.userId === i.id) {
              const indexOfUserInContactList = contactsAndContactsWithChats.findIndex(findI => findI.id === el.userId)
              contactsAndContactsWithChats[indexOfUserInContactList].conversationId = el.conversationId
            }
          })
        })
        return null // No need to return anything, but must return something
      })

      return contactsAndContactsWithChats.filter(searchFilterFunctionForContacts)
    })

    const filteredChatsContacts = computed(() => {
      const conversations = []

      userConversations.value.forEach(element => {
        element.associated.forEach(el => {
          if (el.userId !== currentUser.value.id) {
            const contact = props.contacts.filter(i => i.id === el.userId)[0]
            const contactAndChat = { ...el, ...contact, lastMessage: element.lastMessage }
            conversations.push(contactAndChat)
          }
        })
      })
      return conversations.filter(searchFilterFunctionForContacts)
    })

    return {
      // Search Query
      searchQuery,
      filteredChatsContacts,
      filteredContacts,

      // UI
      resolveChatContact,
      perfectScrollbarSettings,
    }
  },
}
</script>
